<template>
  <div class="houseAuditForm-container">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="房号基础信息">
          <el-form-item
            label="所属项目"
          >
            <span>{{communityName}}</span>
          </el-form-item>
          <el-form-item
            label="房号信息"
          >
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <el-form-item
                >
                <v-autocomplete v-bind="blockParams" :extra="extraBlockParams" v-model="form.blockName" placeholder="苑" :width="width"></v-autocomplete>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <el-form-item
                  prop="buildingNumber"
                  :rules="[{ required: true, message: '请输入楼幢/停车场', trigger: 'change'}]"
                >
                  <v-autocomplete v-bind="buildingParams" :extra="extraBuildingParams" v-model="form.buildingNumber" placeholder="楼幢/停车场" :width="width"></v-autocomplete>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <el-form-item>
                  <v-autocomplete v-bind="unitParams" :extra="extraUnitParams" v-model="form.unit" placeholder="单元/车区" :width="width"></v-autocomplete>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <el-form-item>
                  <v-input v-model="form.room" placeholder="室/车位/车库" :width="width"></v-input>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <!--<el-form-item
            label="租户配置项"
          >
            <v-input v-model="form.newwinHouseNum" :width="300" :maxlength="60"></v-input>
          </el-form-item> -->
          <el-form-item
            label="所属楼层"
            prop="floor"
            :rules="[{ required: true, message: '请输入所属楼层', trigger: 'blur'}]"
          >
            <v-input-number v-model="form.floor" :min="-99" :max="999"  placeholder="请输入所属楼层" :width="width"></v-input-number>
            <span class="tip-text">层/楼</span>
          </el-form-item>
          <el-form-item
            label="是否用于测试或展示"
            prop="isVirtual"
            :rules="[{ required: true, message: '请选择是否用于测试或展示', trigger: 'change'}]"
          >
            <v-select v-model="form.isVirtual" :options="isVirtualOps" :width="width"></v-select>
            <span class="tip-text">用于测试或展示的房号不计入统计数据</span>
          </el-form-item>
          <el-form-item
            label="建筑类型"
            prop="buildType"
            :rules="[{ required: true, message: '请选择建筑类型', trigger: 'change'}]"
          >
            <v-select v-model="form.buildType" :options="buildTypeOps" :width="width"></v-select>
          </el-form-item>
          <el-form-item
            label="房屋类型"
            prop="houseType"
            :rules="[{ required: true, message: '请选择房屋类型', trigger: 'change'}]"
          >
            <v-select v-model="form.houseType" :options="houseTypeOps" :width="width"></v-select>
            <span class="tip-text">注：当房屋类型为公寓、住宅、排屋、别墅、商铺、办公用房、经营用房时，才能显示在App中，被用户关联</span>
          </el-form-item>
          <el-form-item
            v-if="form.houseType==24||form.houseType==25"
            label="是否为子母车位"
            prop="isParent"
            :rules="[{ required: true, message: '请选择', trigger: 'change'}]"
          >
            <v-select v-model="form.isParent" :options="isServerCenterOps" :width="width"></v-select>
            <span class="tip-text">注：子母车位涉及与第三方车场车辆管理等核心业务，请慎重操作</span>
          </el-form-item>
          <el-form-item
            label="是否为服务中心"
            prop="isServerCenter"
            :rules="[{ required: true, message: '请选择', trigger: 'change'}]"
          >
            <v-select v-model="form.isServerCenter" :options="isServerCenterOps" :width="width"></v-select>
            <span class="tip-text">注：服务中心房号涉及工单报事等核心业务，请慎重操作</span>
          </el-form-item>
          <el-form-item
            label="是否关联第三方户号"
            prop="isHaveThirdhouse"
            :rules="[{ required: true, message: '请选择', trigger: 'change'}]"
          >
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <v-select  v-model="form.isHaveThirdhouse" :options="isHaveThirdhouseOps" :width="width"></v-select>
              </div>
              <template v-if="form.isHaveThirdhouse">
                <div class="elform-item-wrapper">
                  <span>第三方名称：</span>
                  <el-form-item
                    prop="ncName"
                    :rules="[{ required: true, message: '请选择', trigger: 'change'}]"
                  >
                    <v-select :disabled="true" v-model="form.ncName" :options="ncNameOps" :width="width"></v-select>
                  </el-form-item>
                </div>
                <div class="elform-item-wrapper">
                  <span>第三方户号：</span>
                  <el-form-item 
                  prop="ncHouse"
                  :rules="[{ required: true, message: '请输入第三方户号', trigger: 'blur'}]">
                    <v-input  v-model="form.ncHouse" placeholder="请输入第三方户号" :maxlength="50"  :width="width"></v-input>
                  </el-form-item>
                  <v-button style="height:36px;margin-left:15px" text="添加第三方车位号" @click="addParkingNumber"></v-button>  
                </div> 
              </template> 
            </div>
            
          </el-form-item>

          <div class="parkingNum" v-if="form.isHaveThirdhouse">
            <el-form-item v-for="(item,index) in form.ncGarages" :key="index" :label="`第三方车位号${index+1}`" :prop="`ncGarages.${index}`" :rules="[{required: true, message: '请输入第三方车位号', trigger:'blur'}]">
              <v-input v-model="form.ncGarages[index]" placeholder="请输入第三方车位号" :maxlength="50" :width="200"></v-input>
              <v-button style="margin-left:10px" type="danger" text="删除" @click="delParkingNumber(index)"></v-button>
            </el-form-item>
          </div>

        </col2-block>
        <col2-block title="房号状态">
          <col2-item :span="8" >
            <div style="position:relative">
              <el-popover
                placement="top-start"
                width="240"
                trigger="hover">
                <div>
                  空置：房屋未售</br> 未领：房屋已售，业主未入伙</br>  空关：房屋已售，业主已入伙未入住</br>  装修：房屋正在装修</br>  入住：装修完成，业主已入住
                </div>
                <i slot="reference" class="el-icon-question infoSupportType"> </i>
              </el-popover>
              <el-form-item
                label="房屋状态"
                prop="roomStatus"
                :rules="[{ required: true, message: '请选择房屋状态', trigger: 'change'}]"
              >
                <v-select v-model="form.roomStatus" :width="width" :options="roomStatusOps"></v-select>
              </el-form-item>
            </div>
            
          </col2-item>
          <col2-item :span="8">
                <el-form-item
                  label="是否可售"
                  prop="canSell"
                  :rules="[{ required: true, message: '请选择是否可售', trigger: 'change'}]"
                >
                  <v-select v-model="form.canSell" :width="width" :options="saleTypeOps"></v-select>
                </el-form-item>
          </col2-item>
          <col2-item :span="8">
            <el-form-item
              v-if="form.canSell === 1"
              label="是否可售"
              prop="isSell"
              :rules="[{ required: true, message: '请选择出售状态', trigger: 'change'}]"
            >
              <v-select v-model="form.isSell" :width="width" :options="saleStatusOps"></v-select>
            </el-form-item>
          </col2-item>
        </col2-block>
        <col2-block>
          <col2-item :span="8">
            <el-form-item
              label="使用状态"
              prop="usingStatus"
              :rules="[{ required: true, message: '请选择使用状态', trigger: 'change'}]"
            >
              <v-select v-model="form.usingStatus" :width="width" :options="usingStatusOps"></v-select>
            </el-form-item>
          </col2-item>
          <col2-item :span="8">
            <el-form-item
              label="是否可租"
              prop="canLease"
              :rules="[{ required: true, message: '请选择是否可租', trigger: 'change'}]"
            >
              <v-select v-model="form.canLease" :width="width" :options="rentTypeOps"></v-select>
            </el-form-item>
          </col2-item>
          <col2-item :span="8">
            <el-form-item
              label="出租状态"
              v-if="form.canLease === 1"
              prop="isLease"
              :rules="[{ required: true, message: '请选择出租状态', trigger: 'change'}]"
            >
              <v-select v-model="form.isLease" :width="width" :options="rentStatusOps"></v-select>
            </el-form-item>
          </col2-item>
        </col2-block>
        <col2-block title="其他信息">
          <el-form-item
            label="排序"
            prop="sort"
            :rules="[{ required: true, message: '请输入排序', trigger: 'blur'}]"
          >
            <v-input-number v-model="form.sort" :min="0" :max="999"  placeholder="请输入排序" :width="width"></v-input-number>
            <span class="tip-text">注：排序值用于资产剖面图中同层排序，数值越小排列越左</span>
          </el-form-item>
          <col2-item :span="8">
            <el-form-item
              label="计费面积"
              prop="chargingArea"
              :rules="[{ required: true, message: '请输入计费面积', trigger: 'blur'}]"
            >
              <v-input-number v-model="form.chargingArea" v-bind="areaNumParams" placeholder="请输入计费面积" :width="width"></v-input-number>
            </el-form-item>
          </col2-item>
          <col2-item :span="8">
            <el-form-item
              label="建筑面积"
              prop="buildingArea"
              :rules="[{ required: true, message: '请输入建筑面积', trigger: 'blur'}]"
            >
              <v-input-number v-model="form.buildingArea" v-bind="areaNumParams" placeholder="请输入建筑面积" :width="width"></v-input-number>
            </el-form-item>
          </col2-item>
          <col2-item :span="24" v-show="isOpen">
            <col2-item :span="8">
              <el-form-item
                label="房屋户型"
              >
                <v-input v-model="form.roomStyle" placeholder="请输入房屋户型" :width="width" :maxlength="30"></v-input>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="欠费金额"
              >
                <span>{{form.propertyFeeArrearage}}</span>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="缴纳日期"
              >
                <span>{{propertyFeePaidToDate}}</span>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="套内面积"
              >
                <v-input-number v-model="form.innerArea" v-bind="areaNumParams" placeholder="请输入套内面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="分摊面积"
              >
                <v-input-number v-model="form.publicArea" v-bind="areaNumParams" placeholder="请输入分摊面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="辅助面积"
              >
                <v-input-number v-model="form.assistChargingArea" v-bind="areaNumParams" placeholder="请输入辅助面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="花园面积"
              >
                <v-input-number v-model="form.gardenArea" v-bind="areaNumParams" placeholder="请输入花园面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="地下室面积"
              >
                <v-input-number v-model="form.undergroundArea" v-bind="areaNumParams" placeholder="请输入地下室面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item
                label="统计面积"
              >
                <v-input-number v-model="form.statisticalArea" v-bind="areaNumParams" placeholder="请输入统计面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
          </col2-item>
          <!-- <el-form-item label="图片">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.housePicList" :limit="9" :fileSize="5">
              <template #tip>
                <span>第一张图片将作为封面图，每张图片大小不超5M，最多可上传9张图片</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item  label="租金">
            <div class="elform-item-custom-wrapper">
              <el-form-item class="elform-item-wrapper" >
                <v-input-number
                    v-model="form.leasePrice"
                    placeholder="请填写租金金额"
                    :min="0.01"
                    :max="99999999.99"
                    :precision="2"
                    :width="width"
                  ></v-input-number>
              </el-form-item>
              <el-form-item>
                <v-select v-model="form.leaseUnit" :options="leaseUnitOps" :width="width" placeholder="请选择单位"></v-select>
              </el-form-item>
            </div>
          </el-form-item> -->
          <col2-item :span="24" class="click-more">
            <span class="btn" v-show="!isOpen" @click="isOpen=true">展开全部信息 <i class="el-icon-arrow-down"></i></span>
            <span class="btn" v-show="isOpen" @click="isOpen=false">收起 <i class="el-icon-arrow-up"></i></span>
          </col2-item>
        </col2-block>
        <col2-block title="操作信息">
          <el-form-item
            label="审核状态"
            prop="status"
            :rules="[{ required: true, message: '请选择审核状态', trigger: 'change'}]"
          >
            <v-select v-model="form.status" :width="width" :options="statusOps"></v-select>
          </el-form-item>
          <el-form-item
            label="审核备注"
            prop="remark"
            :rules="[{ required: true, message: '请输入备注', trigger: 'change'}]"
          >
            <v-textarea v-model="form.remark" :maxlength="100"  placeholder="请输入备注" ></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { getRoomAuditInfoURL,
  passRoomAuditURL,
  // getCommunityRommTypesURL,
  // queryBlockNameFromCommunityURL,
  uploadURL,
  getCommunityRoomNumLeaseUnitURL
} from './api'
import {
  roomStatusOps,
  verifyStatusOps,
  isVirtualOps,
  usingStatusOps,
  buildTypeOps,
  // new add
  saleTypeOps,
  saleStatusOps,
  rentTypeOps,
  rentStatusOps,
  isServerCenterOps,
  isHaveThirdhouseOps
} from './map'
import { vAutocomplete } from 'components/control'
import { Col2Detail, Col2Block, Col2Item } from 'components/bussiness'
import {
  select2BlockURL,
  select2BuildingURL,
  select2UnitURL
} from 'common/api'
import { getHouseTypeData } from 'common/networkData'

export default {
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    vAutocomplete
    // vUploader
  },
  data () {
    return {
      width: 180,
      uploadURL: uploadURL,
      leaseUnitOps: [],
      saleTypeOps: saleTypeOps(),
      saleStatusOps: saleStatusOps(),
      rentTypeOps: rentTypeOps(),
      rentStatusOps: rentStatusOps(),
      roomStatusOps: roomStatusOps(2),
      isServerCenterOps: isServerCenterOps(2),
      statusOps: verifyStatusOps,
      isVirtualOps,
      usingStatusOps,
      buildTypeOps,
      isHaveThirdhouseOps:isHaveThirdhouseOps(2),
      ncNameOps:[{text:'用友',value:'用友'}],
      communityName: '',
      propertyFeePaidToDate: '',
      houseTypeOps: [],
      blockNameList: [],
      submitConfig: {
        submitMethod: 'put',
        queryUrl: getRoomAuditInfoURL,
        submitUrl: passRoomAuditURL,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      form: {
        id: '',
        communityId: '',
        isVirtual: undefined,
        houseType: undefined,
        roomStatus: undefined,
        usingStatus: undefined,
        buildType: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        newwinHouseNum: '',
        floor: undefined,
        buildingArea: null,
        chargingArea: null,
        assistChargingArea: null,
        gardenArea: null,
        innerArea: null,
        publicArea: null,
        undergroundArea: null,
        statisticalArea: null,
        source: undefined,
        roomId: '',
        status: undefined,
        inuser: '',
        remark: '',
        propertyFeeArrearage: '',
        roomStyle: '',
        carportType: undefined,
        // new add
        sort: undefined,
        canSell: 1,
        isSell: 0,
        canLease: 1,
        isLease: 0,
        isServerCenter: 0,
        isParent: 0,
        // // OS-2478 楼宇罗盘房号对应图片上传
        // // 房号图片列表
        // housePicList: [],
        // // OS-2485 楼宇罗盘房号对应租金录入-前端开发
        // // 租金
        // leasePrice: undefined,
        // // 租金单位
        // leaseUnit: undefined
        // 是否关联第三方房号
        isHaveThirdhouse:undefined,
        // 第三方名称
        ncName:'用友',
        // 第三方户号
        ncHouse:'',
        ncGarages:[]
      },
      areaNumParams: {
        precision: 2,
        max: 9999.99,
        min: 0
      },
      isOpen: false,
      blockParams: {
        searchUrl: select2BlockURL,
        valueKey: 'name'
      },
      buildingParams: {
        searchUrl: select2BuildingURL,
        valueKey: 'name'
      },
      unitParams: {
        searchUrl: select2UnitURL,
        valueKey: 'name'
      }
    }
  },

  computed: {
    extraBlockParams () {
      return {
        communityId: this.form.communityId
      }
    },
    extraBuildingParams () {
      return {
        communityId: this.form.communityId,
        blockName: this.form.blockName
      }
    },
    extraUnitParams () {
      return {
        communityId: this.form.communityId,
        blockName: this.form.blockName,
        buildingNumber: this.form.buildingNumber
      }
    }
  },
  mounted () {
    // this.getCommunityRoomNumLeaseUnit()
    this.$refs.formPanel.getData({ id: this.$route.query.id })
    this.getCommunityRommTypes()
  },
  methods: {
    update (data) {
      let keyArr = Object.keys(this.form)
      let newObj = {}
      data.status = data.status || undefined
      // data.leasePrice = data.leasePrice || undefined
      // data.leaseUnit = data.leaseUnit || undefined
      data.buildType = data.buildType ? parseInt(data.buildType) : undefined
      // data.housePicList = data.housePicList || []
      data.houseType = String(data.houseType)
      keyArr.forEach(value => {
        newObj[value] = data[value]
      })
      this.form = newObj
      this.form.ncName = "用友"
      this.communityName = data.communityName
      this.propertyFeePaidToDate = data.propertyFeePaidToDate
    },
    submitBefore (data) {
      // 校验第三方车位号是否重复
      let newNcGarages = [...new Set(data.ncGarages)]
      if (data.isHaveThirdhouse && data.ncGarages && newNcGarages.length < data.ncGarages.length) {
        this.$message.warning('存在重复的第三方车位号')
        return false
      }
      if(data.houseType!=24&&data.houseType!=25){
        data.isParent = 0
      }
      if (data.canSell === 0) {
        data.isSell = 0
      }
      if (data.canLease === 0) {
        data.isLease = 0
      }
      data.gardenArea = data.gardenArea || 0
      data.undergroundArea = data.undergroundArea || 0
      data.innerArea = data.innerArea || 0
      data.publicArea = data.publicArea || 0
      data.assistChargingArea = data.assistChargingArea || 0
      data.statisticalArea = data.statisticalArea || 0

      // data.housePicList = data.housePicList && data.housePicList.length ? data.housePicList : undefined
      // if (data.leasePrice && (!data.leaseUnit)) {
      //   this.$message('请选择单位')
      //   return false
      // }

      // if (data.leaseUnit && (!data.leasePrice)) {
      //   this.$message('请填写租金金额')
      //   return false
      // }
      // data.leasePrice = data.leasePrice || 0
      // data.leaseUnit = data.leaseUnit || 0

      let blockName = (data.blockName) ? '-' + data.blockName : ''
      let buildingNumber = (data.buildingNumber) ? '-' + data.buildingNumber : ''
      let unit = (data.unit) ? '-' + data.unit : ''
      let room = (data.room) ? '-' + data.room : ''
      let dataObject = `${this.communityName}${blockName}${buildingNumber}${unit}${room}`
      data.dataObject = dataObject
      if(!data.isHaveThirdhouse){
        data.ncName = ''
        data.ncHouse = ''
      }
      // 添加第三方车位
      // data.ncGarages.forEach ((item,index)=>{
      //   data[`ncGarage${index+1}`] = item
      // })
      // delete data.ncGarages
      if (data.isHaveThirdhouse && data.ncGarages && data.ncGarages.length) {
        data.ncGarages = data.ncGarages.toString()
      }else {
        delete data.ncGarages
      }

      return true
    },
    async getCommunityRommTypes () {
      const { houseTypeOps } = await getHouseTypeData()
      this.houseTypeOps = houseTypeOps()
    },
    getBlockNameList (id) {
      this.$axios.get(select2BlockURL, {
        params: { communityId: id }
      }).then(res => {
        if (res.status == 100) { //eslint-disable-line
          let list = res.data || []
          this.blockNameList = list.map(item => {
            return item.name
          })
        }
      })
    },
    blockNameSearch (queryString, cb) {
      let list = this.blockNameList
      let results = queryString ? list.filter(this.createFilter(queryString)) : list
      results = results.map(value => {
        return { value }
      })
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    // 获取房号的租金单位类型
    getCommunityRoomNumLeaseUnit () {
      this.$axios.get(getCommunityRoomNumLeaseUnitURL).then(res => {
        if (res.status === 100) {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.name,
              value: Number(val.code)
            }
          }) || []
          let tempOps = [
            {
              text: '请选择单位',
              value: undefined
            }
          ]
          this.leaseUnitOps = tempOps.concat(ops)
          // if (!this.form.leaseUnit) {
          //   let defaultVal = ops[0] ? ops[0].value : undefined
          //   this.$set(this.form, 'leaseUnit', defaultVal)
          // }
        }
      })
    },
    // 添加第三方车位号
    addParkingNumber () {
      if (this.form.ncGarages && this.form.ncGarages.length>=10) {
        return this.$message.warning('最多添加10个')
      } 
      if (!this.form.ncGarages) {
        this.form.ncGarages = []
        this.form.ncGarages.push('')
      }else {
        this.form.ncGarages.push('')
      }
    },
    delParkingNumber (index) {
      this.form.ncGarages.splice(index,1)
    }
  }

}
</script>
<style lang="scss" scoped>
.houseAuditForm-container {
  .elform-content-wrapper {
    display: flex;
    justify-content: flex-start;

    .mark-color {
      color: red;
      margin-right: 5px;
    }

    .mark-text {
      margin-right: 5px;
      font-weight: bold;
      color: #333;
      width: 70px;
      flex-shrink: 0;
    }
  }

  .elform-item-wrapper {
      display: flex;
      margin-right: 20px;
    }

  .elform-item-custom-wrapper {
    display: flex;
    flex-direction: row;
  }

  .tip-text {
    margin-left: 10px;
  }
  .click-more {
    // border-top:1px solid #DCDFE6;
    text-align:center;
    .col2-item {
      padding-left:80px;
    }
    .btn {
      display: block;
      color: #C0C4CC;
      cursor: pointer;
    }
  }

  .infoSupportType{
    position: absolute;
    left: 146px;
  }
  .parkingNum{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
